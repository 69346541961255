@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-project {
  0% {
    scale: 1;
  }
  100% {
    scale: 1.025;
  }
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #294F70;
  min-height: 100vh;
  font-size: 1.25rem;
}

.content {
  flex: 1
}

#root {
  --background-color: #000000;
  --text-color: #FFFFFF;
  --link-color: #95C0FE;
  --project-background-color: rgba(136, 196, 236, 1);
}

.App a {
  color: var(--link-color);
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.custom-navbar {
  margin-bottom: 3rem;

}

.custom-navbar-name {
  background-color: var(--project-background-color);
  color: white;
  font-family: Serif, serif;
  font-size: 3rem;
}

.custom-navbar-subtitle {
  font-size: 1.5rem;
}

.custom-navbar-nav {
  background: #000000;
  color: white;
  display: flex;
  height: 5vh;
  gap: 2vw;
  justify-content: center;
  align-items: center;
}

.navbar-item {
  background-color: #000000;
  border-radius: 8px;
  transition: background-color 500ms ease;
}

.navbar-item:hover {
  cursor: pointer;
  background-color: #2a2a2a;
}

.footer-bar {
  font-size: 1.1rem;
  margin-top: auto;
  color: var(--text-color);
  background-color: var(--background-color);
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.small-dropdown {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  color: white;
  width: 50%;
  border: 1px solid #545454;
  border-radius: 8px;
  transform: translateX(50%);
}

.navbar-dropdown-item {
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #000000;
  border-radius: 8px;
  transition: background-color 500ms ease;
}

.navbar-dropdown-item:hover {
  cursor: pointer;
  background-color: #2a2a2a;
}

.project {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  transition: transform 0.3s;
}

.project:hover {
  transform: scale(1.025);
}

.about-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-link-svg {
  transition: color 1000ms ease;
}

.about-link-svg:hover {
  fill: #ababab;
}

.about-small-screen {
  margin-bottom: 3rem;
}
.about-small-screen p {
  padding-right: 5vw;
  padding-left: 5vw;
}

.about-small-screen ul li {
  list-style-type: none;
  font-size: 0.9em;
}

.about-small-screen .about-flex-vertical {
  display: flex;
  flex-direction: column;
}

.about-small-screen .contact-info {
  margin-top: 3rem;
  margin-bottom: 3rem;
  justify-content: center;
  flex-wrap: wrap;
}

.about-small-screen .contact-info .find-me-on {
  margin-left: auto;
  margin-right: auto;
}

.languages-and-tools h2 {
  margin-bottom: 3vmin;
}

.languages-and-tools .container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: center;
  margin-bottom: 3vmin;
}

.languages-and-tools .lang-tool {
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  margin-left: 1vmin;
  margin-right: 1vmin;
  max-width: 12.5vw;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  font-size: calc(0.5rem + 0.5vw);
}

.mini-projects table {
  width: 100%;
  table-layout: fixed;
}

.mini-projects td {
  height: 20px;
  overflow: hidden;
  padding-bottom: 1rem;
}

.mini-projects a {
  color: #0000ee;
}



